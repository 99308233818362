<template>
  <div>
    <v-card>
      <v-card-text>
        <v-overlay :value="loading">
          <v-progress-circular
              indeterminate
              size="64"
              color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col>
            <v-text-field
                outlined
                dense
                label="ລະຫັດຕຸກນ້ຳ"
                type="text"
                v-model="barcode"
                @keyup.enter="fetchData()"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn class="btn white--text btn-primary elevation-0" @click="ClearData">
              Clear
            </v-btn>
          </v-col>
        </v-row>
        <h2 v-if="first_round.length > 0" class="primary-color">ຮອບວຽນລ່າສຸດ</h2>
        <v-timeline v-if="second_round.length > 0" dense fill-dot>
          <v-timeline-item
              v-for="(track, i) in first_round"
              v-bind:value="track.id"
              :key="i"
              class="mb-1"
              color="#00c1d2"
              icon="mdi-arrow-up-circle"
              icon-color="primary-color lighten-2"
              large
          >
            <v-row justify="space-between">
              <v-col cols="9">
                <h2 class="font-weight-bold">
                  {{ getTrackStatusType(track.status_type) }}
                  {{ getTrackStoreType(track.store_type) }}
                </h2>
                <span v-if="track.driver"
                >ທະບຽນລົດ: {{ track.driver.license_plate }}</span
                >
                <span v-if="track.branch">
                      ສາຂາ: {{ track.branch.name }}</span
                >
                <span v-if="track.customer">
                      ລູກຄ້າ: {{ track.customer.name }} / ໂທ:
                      {{ track.customer.phone }}</span
                >
                <p class="green--text">{{ track.status }}.</p>
              </v-col>
              <v-col class="text-left" cols="3">
                    <span>
                      {{
                        moment(track.created_at).format("HH:MM / DD-MM-YYYY")
                      }}</span
                    >
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
         <h2 v-if="second_round.length > 0" class="primary-color">ຮອບວຽນກ່ອນໜ້າ</h2>
        <v-timeline v-if="first_round.length > 0" dense fill-dot>
          <v-timeline-item
              v-for="(track, i) in first_round"
              v-bind:value="track.id"
              :key="i"
              class="mb-1"
              color="#00c1d2"
              icon="mdi-arrow-up-circle"
              icon-color="primary-color lighten-2"
              large
          >
            <v-row justify="space-between">
              <v-col cols="9">
                <h2 class="font-weight-bold">
                  {{ getTrackStatusType(track.status_type) }}
                  {{ getTrackStoreType(track.store_type) }}
                </h2>
                <span v-if="track.driver"
                >ທະບຽນລົດ: {{ track.driver.license_plate }}</span
                >
                <span v-if="track.branch">
                      ສາຂາ: {{ track.branch.name }}</span
                >
                <span v-if="track.customer">
                      ລູກຄ້າ: {{ track.customer.name }} / ໂທ:
                      {{ track.customer.phone }}</span
                >
                <p class="green--text">{{ track.status }}.</p>
              </v-col>
              <v-col class="text-left" cols="3">
                    <span>
                      {{
                        moment(track.created_at).format("HH:MM / DD-MM-YYYY")
                      }}</span
                    >
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      barcode: "",
      loading: false,
      first_round: [],
      second_round: [],
    }
  },
  metaInfo() {
    return {
      title: `ຕິດຕາມກຕຸກນ້ຳ`,
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      await this.$axios.get(`/history-track-order-status/${this.barcode}`).then(res => {
        this.first_round = res.data.round1;
        this.second_round = res.data.round2;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    ClearData() {
      this.first_round = [],
          this.second_round = []
      this.barcode = "";
    },
    getTrackStatusType(status) {
      if (status == "inbound") return "ເຂົ້າ";
      else if (status == "outbound") return "ອອກຈາກ";
      else return;
    },
    getTrackStoreType(status) {
      if (status == "factory") return "ໂຮງງານ";
      else if (status == "truck") return "ລົດຂົນສົ່ງ";
      else if (status == "branch") return "ສາຂາ";
      else if (status == "customer") return "ລູກຄ້າ";
      else return;
    },
  },
  watch: {
    barcode: function (value) {
      if (value.length > 8 && value.length < 15){
        this.fetchData();
      }
    }
  }
};
</script>

<style>
.main-color {
  color: #00c1d2 !important;
}

.main-icon {
  color: #00c1d2 !important;
  font-size: 2.5rem !important;
}

.v-active {
  font-size: 14px;
  color: #00c1d2;
  font-weight: 300;
  margin-right: 8px;
}

.none-active {
  font-size: 14px;
  color: #e74c3c;
  font-weight: 300;
}

.v-title {
  font-weight: 600;
  color: #303a49;
  font-size: 1.1rem;
}

.main-title {
  color: #303a49;
  font-size: 1.3rem;
}

.count-user {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
}

.right-item {
  display: inline-block;
  width: 90%;
}

.left-item {
  margin: auto;
  margin-right: 18px;
}

.v-divider {
  color: #303a49;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.v-progress-linear {
  border-radius: 12px;
  margin: 0;
  background-color: #000000 !important;
}

.progress-bar-txt {
  position: relative;
  font-size: 12px;
  left: 6px;
  z-index: 1;
}

.v-application .primary {
  background-color: #00c1d2 !important;
  border-color: #00c1d2 !important;
}

.v-progress-linear__content {
  color: white !important;
}

.apexcharts-legend-series {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
  font-family: "Roboto", "NotoSanLao" !important;
}

.apexcharts-legend-series span {
  font-size: 14px !important;
  font-family: "Roboto", "NotoSanLao" !important;
}
</style>
